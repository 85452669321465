<template>
<v-dialog :value="value" scrollable max-width="1200px">
    <v-card height="90vh" max-height="calc(100vh - 60px)">
      <v-card-title class="pa-0">
      <v-toolbar color="primary" flat dark height="48px" dense>
        <div>Transaction Map</div>
        <v-spacer />
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>  
      </v-card-title>   
      <v-card-text class="pa-0">
        <MapBox :mapItems="value" />
        <div class="pin-count">{{value.length}}</div>
      </v-card-text>    
    </v-card>
  </v-dialog>
</template>

<script>
import MapBox from "@/components/MapBox/MapBox.vue";

export default {
  name: "MapBoxDialog",
  components: {
      MapBox
  },

  props: {
      value: { type: Array },
      width: {type: String, default: '100%' },
      height: { type: String, default: '100%'},
      persistent: { type: Boolean, default: false }
  },     
  data: () => ({}),
  computed: {
    isSmallScreen() {
      const screenSizes = ['xs','sm'];
      return screenSizes.indexOf(this.$vuetify.breakpoint.name) >= 0;
    },
  },
  methods: {
    close() {
        this.$emit("close");
    },        
  },
  watch: {},
  mounted() {
  },
};
</script>


<style scoped>
.pin-count {
  position: absolute;
  top: 65px;
  left: 0;
  opacity: .4;
}
.v-dialog {
  height: calc(100% - 60px);
}
</style>
