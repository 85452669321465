<template>
    <div id="map"></div>
</template>

<script>
import mapboxgl from "mapbox-gl";

export default {
    name: "MapBox",
    props: {        
        mapItems: {type: Array, default: () => []},
        popupFields: {type: Array, default: () => []},
        showPopups: {type: Boolean, default: true },
        width: {type: String, default: '100%' },
        height: { type: String, default: '440px'},        
    },
    data() {
        return {
            accessToken: 'pk.eyJ1IjoiYWFyb25jYXJsc29uIiwiYSI6ImNrNmdzbnZkbjJtb2Ezb3Fqd2FvcjNlZTgifQ.LPnTDyN_33BCxWw0R0SAxw',
            map: null,
            markers: [],
        };
    },
    methods: {
        initMap() {
            if (!this.mapItems || this.mapItems.length === 0) {
                return;
            }
            mapboxgl.accessToken = this.accessToken;            

            this.map = new mapboxgl.Map({
                container: "map",
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [this.mapItems[0].longitude, this.mapItems[0].latitude],
                zoom: 14,
                interactive: true
            });

            this.map.on("load", () => {
                this.map.addControl(new mapboxgl.ScaleControl({ maxWidth: 100, unit: "standard" }));

                if (this.markers.length > 0) {
                    this.resetMarkers();
                }

                if (this.mapItems) {
                    this.updateMarkers();
                }
            });      
            
            this.$nextTick(() => this.map.resize());
        },
        resetMarkers() {
            this.markers.forEach((marker) => {
                marker.remove();
            });
            this.markers = [];
        },
        updateMarkers() {
            this.markers = this.mapItems
                .map((mapItem) => {
                    const el = document.createElement("div");
                    el.className = "marker";
                    
                    if (this.showPopups && mapItem.popup) {
                        const popup = this.mapItemPopup(mapItem);
                        return new mapboxgl.Marker({ color: '#FF0000' })
                            .setLngLat([mapItem.longitude, mapItem.latitude])
                            .setPopup(popup)
                            .addTo(this.map);
                    }
                 
                    return new mapboxgl.Marker({ color: '#FF0000' })
                            .setLngLat([mapItem.longitude, mapItem.latitude])
                            .addTo(this.map);
                });

            if (this.mapItems.length === 1) {            
                const center = new mapboxgl.LngLat(this.mapItems[0].longitude, this.mapItems[0].latitude);                
                this.map.setCenter(center);                    
            } else {
                this.setMapBounds();
            }            
        },
        setMapBounds() {
            const coordinates = this.mapItems.map(mi => [...[mi.longitude,mi.latitude]]);

            // Create a 'LngLatBounds' with the first coordinate.
            const bounds = new mapboxgl.LngLatBounds(
                coordinates[0],
                coordinates[0]
            );
 
            // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
            for (const coord of coordinates) {
                bounds.extend(coord);
            }

            // Note there are other options such as easeing animation and maxZoom
            this.map.fitBounds(bounds, {
                padding: 40
            })
        },
        mapItemPopup(mapItem) {
            if (!mapItem.popup) {
                return;
            }
            
            let html = `
                <div class="card"><div class="card-body">
                <h3 class="font-weight-bold text-center">${mapItem.popup.title}</h3>
            `;
            
            if (mapItem.popup.fields && mapItem.popup.fields.length > 0)  {
                html += `
                    <v-divider></v-divider>
                    <table><tbody>
                `;
                mapItem.popup.fields.forEach(f => {
                    html += `<tr><td class="pr-1 font-weight-bold">${f.label}</td><td>${f.value}</td></tr>`    
                })
                html += `</tbody></table>`
            }                        
            this.html += `</div></div>`;                

            return new mapboxgl
                .Popup({ offset: 25 })
                .setHTML(html);
        },
    },
    mounted() {
        this.initMap();   
    },
    watch: {
        mapItems: {
            handler() {
                if (this.mapItems) {
                    this.resetMarkers();
                    this.updateMarkers();                    
                }
            },
            deep: true
        } 
    }
}
</script>

<style scoped>        
    #map {
        position: relative; 
        top: 0; 
        bottom: 0; 
        left: 0;
        right: 0;
        padding: 0;
        margin: 0;
        height: 100%;
        width: 100%;
    }

    /* #map {
        position: relative;
        width:100%;
        height:100%;        
        border-radius: 0.25rem;
        border: 1px solid #ccc;
        margin: 0 auto;
    }     */
</style>