<template>
  <v-dialog :value="value" @input="$emit('input', value)" max-width="400px" persistent @keydown.esc="close()">
    <v-card>
      <v-card-title :class="color + '--text'" class="headline">
        {{ title }}
      </v-card-title>

      <v-card-text class="dialog-text pt-2">
        <slot name="default"></slot>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="actions text-center">
        <v-btn text @click="close()" :disabled="loading" class="mx-6">Cancel</v-btn>
        <v-btn
          :color="color"
          text
          @click="confirm()"
          :loading="loading"
          class="mx-6"
          >{{ submitBtnTitle }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",

  props: ["value", "color", "title", "submitBtnTitle", "loading"],

  data: () => ({}),

  computed: {},
  methods: {
    close() {
      this.$emit("input", "");
    },
    confirm() {
      this.$emit("confirm", this.value);
    },
  },
};
</script>

<style scoped>
.dialog-text {
  font-size: 1.2em;
}

.actions {
  justify-content: center;
  background-color: #f0f0f0;
}
</style>
